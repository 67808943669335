import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from 'app/app-constants';
import { API } from '../../../constants/index';

@Injectable({
  providedIn: 'root'
})
export class SolutionsService {

  constructor(private dataService: DataService) { }

  getAllSolutionSet() {
    const req = {};
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_SOLUTION_SET, req);
  }

  addSolutionSet(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_SOLUTION_SET, req);
  }

  addUpdateSolutionSkills(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_UPDATE_SOLUTION_SKILLS, req);
  }

  addUpdateSolutionContacts(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_UPDATE_SOLUTION_CONTACTS, req);
  }

  addUpdateSolutionIndustries(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_UPDATE_SOLUTION_INDUSTRIES, req);
  }

  addSolutionProjects(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_SOLUTION_PROJECTS, req);
  }

  updateSolutionProjects(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_SOLUTION_PROJECTS, req);
  }

  updateSolutionFiles(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_SOLUTION_FILES, req);
  }

  addSolutionFile(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_SOLUTION_FILES, req);
  }

  updateSolutionSet(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_SOLUTION_SET, req);
  }

  deleteSolutionSet(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_SOLUTION_SET, req);
  }

  getSolutionTags(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_SOLUTION_TAGS, req);
  }

  updateSolutionDescription(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_SOLUTION_DESCRIPTION, req);
  }

}
