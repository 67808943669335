import { MetadataService } from "aws-sdk";

export const environment = {
  production: true,
  apiUrl: 'https://kronos.tarento.com/api/v1/',
  apiUrl1: 'https://kronos.tarento.com/api/',
  googleClientId: '503034479876-urn2420vvurjehcto7klg1tnnver1fpp.apps.googleusercontent.com',
  apiKey: 'AIzaSyCf2d_9fTYPHJK3yQVhtz2Ufp1zJPCGNGM',
  msal: {
    clientId: '54481437-9f88-4a15-ae2f-89b2d13135be',
    authority: 'https://login.microsoftonline.com/d2f80462-bf28-4055-8ef3-085d8e4b2ed0',
    redirecUri: 'https://kronos.tarento.com/redirect'
  },
  styria: {
    prodGateway: 'https://styria.tarento.com/',
    gateway: 'https://styria-dev.tarento.com/',
    feedGateway:'https://styria.tarento.com/',
    integrationServiceVersion: 'v1/'
  },
  matomoAnalyticsService: {
    siteAddress: 'https://matomo.tarento.com/',
    siteId: '1'
  }
};
