import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthenticationService, UtilsService, CommunicationService } from './helpers/services/index';
import { GrowlModule, DragDropModule, DataTableModule, ConfirmDialogModule } from 'primeng/primeng';
import { AuthGuard, LoginGuard } from './helpers/guards';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CookieModule } from 'ngx-cookie';
import { SharedModule } from './modules/shared/shared.module';
import { PopoverModule } from 'ngx-bootstrap';
import { MsalModule } from '@azure/msal-angular';
import { environment } from 'environments/environment';
import { QRCodeModule } from 'angular2-qrcode';
import { sanitizeInputFactory } from './helpers/factories/sanitize-input.factory';
import { DomSanitizer } from '@angular/platform-browser';
import { FilterModulesPipe } from './helpers/directives/pipes/filter-modules/filter-modules.pipe';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
@NgModule({
  declarations: [
    AppComponent,
    // FilterModulesPipe
  ],
  imports: [
    QRCodeModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    GrowlModule,
    DragDropModule,
    CookieModule.forRoot(),
    PopoverModule.forRoot(),
    DataTableModule,
    ConfirmDialogModule,
    AppRoutingModule,
    SharedModule,
    MsalModule.forRoot({
      auth: {
        clientId: environment.msal.clientId,
        authority: environment.msal.authority,
        redirectUri: environment.msal.redirecUri
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
    }, {
      popUp: !isIE,
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: sanitizeInputFactory,
      deps: [DomSanitizer],
      multi: true,
    },
    AuthenticationService, UtilsService, CommunicationService, AuthGuard, LoginGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
