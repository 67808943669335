import { environment } from '../environments/environment';

export const KRONOS = {

    // Base url from which the application is rendered   

    'BASE': environment.apiUrl,
    'BASE1': environment.apiUrl1,

    'USER': 'user/',
    'ADMIN': 'admin/',
    'WEB': 'web/',
    'EXPENSES': 'expense/',
    'REPORT': 'report/',
    'ROLES': 'roles/',
    'PROJECTCHECKIN': 'projectCheckin/',
    'FEEDBACK': 'feedback/',
    'CUSTOMER': 'customer/',

    'POLICY': 'policy/',
    'ANALYTICS': 'analytics/',
    'PROJECT_UPDATE': 'projectUpdate/',
    'AUDIT': 'audit/',
    'LINK': 'link/',
    'AWARD': 'award/',
    'AVAILABILITY': 'availability/',
    'PROJECT': 'project/',
    'FORECAST': 'forecast/',
    'FOOD': 'food/',
    'FINANCE': 'finance/',
    'ESAT': 'esat/',
    'KNOWKRONOS': 'knowKronos/',
    'VENDORS' : 'vendors/',

    // google auth client id
    'GOOGLE_CLIENT_ID': environment.googleClientId,

    // api key
    'API_KEY': environment.apiKey,

    // super admin role id
    'SUPER_ADMIN_ROLE_ID': 5,

    // User directory 
    'PROFILE_TABS': ['OVERVIEW', 'CONTACT', 'COMPANY', 'CAREER', 'PERSONAL'],
    'BLOOD_GROUP': [{ id: 'A+', key: 1, name: 'A+' }, { id: 'A-', key: 2, name: 'A-' }, { id: 'B+', key: 3, name: 'B+' },
    { id: 'B-', key: 4, name: 'B-' }, { id: 'O+', key: 5, name: 'O+' }, { id: 'O-', key: 6, name: 'O-' },
    { id: 'AB+', key: 7, name: 'AB+' }, { id: 'AB-', key: 8, name: 'AB-' }, { id: 'N/A', key: 9, name: 'N/A' }],
    'USER_TYPE': [{ id: 1, key: 1, name: 'Permanent' }, { id: 2, key: 2, name: 'Contract' }, { id: 3, key: 3, name: 'Intern' }],

    // Expense module
    'expenseStatus': ['pending', 'approved', 'rejected', 'waiting'],
    // 'reimbursementKind': ['Certification/Training', 'Domestic travel (inter city)', 'Travel kit', 'Team lunch', 'Vouchers', 'Conveyance - with bill', 'Work From Home Expense'],
    // 'allowanceKind': ['Food', 'On call', 'Shift', 'Conveyance - without bill', 'Overtime'],
    'expenseType': ['All', 'Allowance', 'Reimbursement'],

    /*  Apis are made as constants and are loaded to the services, 
        import the constant file into the services and then call it like - 
        For API calls - KRONOS.BASE_URL + KRONOS.USER + KRONOS.APIS.LOGIN
        For messages - KRONOS.MESSAGES.USERNAME_REQUIRED
        For constants - KRONOS.CONSTANTS.CONSTANTS_NAME
    */

    PLACEHOLDERS: {
        //Admin > Project
        'PROJ_NAME': 'Project name',
        'SELECT_ACCOUNT': 'Select an account',
        'SELECT_UNIT': 'Select a unit',
        'SELECT_TEMPLATE': 'Select a template',
        'SELECT_AUDIT': 'Select a audit template',
        'SELECT_PARENT_PROJ': 'Select a parent project',
        'PROJ_CODE': 'Project code',

        'SELECT_CHANNEL': 'Select a channel',
        'DESCRIPTION': 'Project description',
        'END_DATE': 'Project end-date',
        'NOTES': 'Enter the notes here',
        'ADMIN_NAME': 'Select admins for the project',
        //Admin > Organization
        'ORG_NAME': 'Organization name',
        'ORG_URL': 'Organization URL',
        'USERS_ALLOWED': 'Number of users allowed',
        'DAILY_WORK_HOURS': 'Daily Work Hours',
        'WEEK_WORK_DAYS': 'Weekly Work Days',

        //Expense reporting
        'CLAIM': 'Claim',
        'CATEGORY': 'Category',
        'SEVERITY': 'Severity',
        'PROBABILITY': 'Probability',
        'LESSON_TITLE': 'Title',
        'DATE_OF_INVOICE': 'Date of Invoice',
        'AMOUNT': 'Amount',
        'CLAIM_NOTES': 'Notes',
        'APPROVER': 'Approver',
        'PROJECT': 'Project',
        'UNIT': 'Unit',
        'BILLNO': 'Bill number',

        //categories
        'CATEGORY_NAME': 'Category name',
        'CATEGORY_DESCRIPTION': 'Description here ...',

        //links
        'LINK_URL': 'URL',
        'LINK_TAGS': 'Tags',
        'LINK_COMMENTS': 'Comments',
        'LINK_TYPE': 'Link type',
        'LINK_TITLE': 'Link Title',
        'LINK_CATEGORY': 'New Category',
        //project collation and sharing
        'SHAREABLE_CODE': 'shared code',
        'PROJECT_GROUP': 'Project Group',

        'PROJECT_NAME': 'Please Enter the Project Name',
        'PROJECT_ACCOUNT_NAME': 'Choose the Account Name',
        'PROJECT_UNIT_NAME': 'Choose the Unit Name',
        'PROJECT_TEMPLATE_NAME': 'Choose the Template Name',
        'PARENT_PROJECT': 'Choose the Parent Project',
        'PROJECT_CHANNEL': 'Please Enter the Project Channel',
        'PROJECT_CODE': 'Please Enter the Project Code',
        //projects lessons and risks
        'MITIGATION': 'Mitigation',
        'STATUS': 'Status',
        'LESS_DESCRIPTION': 'description',

        'TEAM_NAME': 'Please Enter the Team Name',
        'TASK_NAME': 'Please Enter the Task Name',
        'DESIGNATION_NAME': 'Please Enter the Designation Name',
        'UNIT_NAME': 'Please Enter the Unit Name',
        'TEMPLATE_NAME': 'Please Enter the template Name',
        'LOADING': 'Loading...',
        'NO_MATCH_FOUND': 'No Match Found...',
        'NO_WEEKWISE_REPORT': 'No weekwise report available',
        'NO_PROJECT_REPORT': 'No project report available',
        'NO_TASK_FOUND': 'No tasks found',
        'PHONE_NUMBER_LENGTH': 'Mobile Number should be 10 digit',

        //Organization Onboarding

        'ORGANIZATION_NAME': 'Name of the organization',
        'WEBSITE': 'Website',
        'ADMIN_EMAIL': 'Admin Email',
        'DOMAIN': 'Domain (Industry)',
        'NO_OF_EMPLOYEES': 'No of employees in the org',

        'WORK_DAYS': 'No of working days in a week',
        'WORK_HOURS': 'Working hours in a day',
        'PMO_EMAIL': 'PMO Email',

        'USER_LIST': 'Upload User List ',

        'PROJECT_LIST': 'Upload Project List '
    }
}

export const STYRIA = {
    'GATEWAY': environment.styria.gateway,
    'PRODGATEWAY': environment.styria.prodGateway,
    'FEEDGATEWAY': environment.styria.feedGateway,
    'INTEGRATION_SERVICE_VER': environment.styria.integrationServiceVersion,

    'ZOHO_PEOPLE': "zohoPeople/",
    'KRONOS': "kronos/",
    'FEED': "feed/",

}

export const MATOMO_ANALYTICS_CONFIG = {
    siteAddress : environment.matomoAnalyticsService.siteAddress,
    siteId : environment.matomoAnalyticsService.siteId
}