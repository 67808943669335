import { Injectable } from '@angular/core';
import { DataService } from '../core/interceptor.service';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';

@Injectable()

export class VendorTypeService {

  constructor(private dataService: DataService) { }

  getAllVendorTypesByOrg(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_VENDOR_TYPES_BY_ORG, req);
  }

  addVendorType(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.ADD_VENDOR_TYPE, req);
  }

  updateVendorType(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.UPDATE_VENDOR_TYPE, req);
  }

  deleteVendorType(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.DELETE_VENDOR_TYPE, req);
  }

}
