import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';
import { SecurityContext } from '@angular/core';

export function sanitizeInputFactory(sanitizer: DomSanitizer) {
  return () => {
    FormControl.prototype['_oldSetValue'] = FormControl.prototype.setValue;

    FormControl.prototype.setValue = function (value: any, options: any) {

      if (typeof value === 'string') {
        const sanitizedValue: SafeValue | null = sanitizer.sanitize(
          SecurityContext.HTML,
          value
        );

        if (sanitizedValue !== null) {
          let restoredValue = sanitizedValue.toString();

          // the restored values comes in html format ( that can be passed of to inner html )
          // we are converting it to normal text format below
          const txt = document.createElement('textarea');
          txt.innerHTML = restoredValue;
          arguments[0] = txt.value;

        } else {
          arguments[0] = '';
        }
      }

      return this['_oldSetValue'].apply(this, arguments);
    };
  };
}
