import {
    trigger,
    state,
    style,
    animate,
    transition, query, stagger, group
} from '@angular/animations';

export const ANIMATION = {
    FLIP: trigger('gridAnimation', [
        transition('void => *', [
            query('#animate', style({
                transform: 'rotateY(90deg)',
            }), { optional: true }),
            query('#animate',
                stagger('10ms', [
                    animate('900ms', style({ transform: 'translateX(0)' }))
                ]), { optional: true })
        ])
    ]),

    APPEAR:
    trigger('gridAnimation', [
        transition('void => *', [
                style({ width: 5, transform: 'translateX(400px)', opacity: 0 }),
                group([
                    animate('0.3s 0.5s ease', style({
                        transform: 'translateX(0)', width: '*'
                    })),
                    animate('0.3s ease', style({
                        opacity: 1
                    }))
                ])
            // style({ width: 5, transform: 'translateY(-50%)', opacity: 0 }),
            // group([
            //     animate('0.3s 0.1s ease-in-out', style({
            //         transform: 'translateY(0%)', width: '*'
            //     })),
            //     animate('0.3s ease', style({
            //         opacity: 1
            //     }))
            // ]),
        ])
    ]),

    SLIDE_IN: trigger('gridAnimation', [
        transition('void => *', [
            query('#animate', style({ transform: 'translateY(-50%)', opacity: 0 })),
            query('#animate',
                stagger('10ms', [
                    animate('200ms 100ms ease-in-out', style({ transform: 'translateY(0)', opacity: 1 }))
                ]), { optional: true })
        ])
    ]),

    SLIDE_IN_ACTIVITY: trigger('slideIn', [
      transition('void => *', [
          query('#animateSlideIn', style({ transform: 'translateY(-50%)', opacity: 0 })),
          query('#animateSlideIn',
              stagger('10ms', [
                  animate('200ms 100ms ease-in-out', style({ transform: 'translateY(0)', opacity: 1 }))
              ]), { optional: true })
      ])
  ]),

    CLICK: trigger('clickAnimate', [
        state('in', style({ transform: 'translateX(0)' })),
        transition('void => *', [
            style({ transform: 'translateX(-100%)' }),
            animate('700ms ease-in')
        ])
    ]),

    ROUTE: trigger('fadeAnimation', [
        transition('* => *', [
            query(
                ':enter',
                [style({ opacity: 0 })],
                { optional: true }
            ),
            query(
                ':leave',
                [style({ opacity: 1 }), animate('0.3s', style({ opacity: 0 }))],
                { optional: true }
            ),
            query(
                ':enter',
                [style({ opacity: 0 }), animate('0.3s', style({ opacity: 1 }))],
                { optional: true }
            )
        ])
    ])
}


