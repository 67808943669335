import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommunicationService } from './helpers/services/index';
import { Message } from 'primeng/primeng';
import { Router, NavigationEnd } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MATOMO_ANALYTICS_CONFIG } from './app-constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  msgs: Message[] = [];
  growlObj: any = {};
  appVersion = '3.15.2';
  isNavbarSticky = false;
  private destroy$ = new Subject<void>();

  constructor(private _myCommunicationService: CommunicationService, private router: Router) {
    this._myCommunicationService.changeEmitted$.subscribe(myMessage => {
      this.growlObj = myMessage;
      this.msgs = [];
      this.msgs.push(this.growlObj);
    });
  }

  ngOnInit() {
    this.injectMatomoScript();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.destroy$)
    ).subscribe((event: NavigationEnd) => {
      this.isNavbarSticky = event.url.includes('/feeds') || event.url.includes('/micro-learning');
      this._myCommunicationService.updateNavbarStickyState(this.isNavbarSticky);
      this.trackPageView(event.urlAfterRedirects);  // Track page views on navigation
    });
  }

  private injectMatomoScript() {
    // Initialize Matomo tracking
    window['_paq'] = window['_paq'] || [];

    window['_paq'].push(['enableLinkTracking']);
    
    // Set up tracker URL and site ID
    const u = MATOMO_ANALYTICS_CONFIG.siteAddress;
    const siteId = MATOMO_ANALYTICS_CONFIG.siteId;
    window['_paq'].push(['setTrackerUrl', u + 'matomo.php']);
    window['_paq'].push(['setSiteId', siteId]);

    // Load Matomo tracking script
    const d = document;
    const g = d.createElement('script');
    const s = d.getElementsByTagName('script')[0];
    g.async = true;
    g.src = u + 'matomo.js';
    s.parentNode.insertBefore(g, s);

    // Track the initial page view once the script is loaded
    g.onload = () => {
      window['_paq'].push(['trackPageView']); // Track the initial page view
    };
  }

  private trackPageView(url: string) {
    if (window['_paq']) {
      window['_paq'].push(['trackPageView', url]);  // Track the page view for subsequent navigations
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
