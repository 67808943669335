export const VALIDATOR_MESSAGES = {
    'F_NAME': 'Please Enter the First Name',
    'L_NAME': 'Please Enter the Last Name',
    'U_AGE': 'Please Enter the Age',
    'U_NAME': 'User Name is Required',
    'GENDER': 'Choose any Gender',
    'MOBILE': 'Please Enter the Mobile Number',
    'MAIL_ID': 'Please Enter the Mail Id',
    'DATE': 'Select Valid Date',
    'BLOOD_GROUP': 'Choose the Blood Group fo the employee',
    'EMP_TYPE': 'Select User Type',
    'EMP_ID': 'Please Enter the Employee Id',
    'EMP_TEAM': 'Select Employee Team',
    'EMP_BUSINESS': 'Choose Business of the Employee',
    'EMP_DESIGNATION': 'Choose Designation of the Employee',
    'FILE_SIZE': 'Image should be less than 100KB',
    'EMP_ROLE': 'Choose the Role of the Employee',
    'CONSULTANT_PROFILE_URL': 'Please enter consultant profile url',
    'NO_CONSULTANT_PROFILE_URL': 'No consultant profile link found',
    'DUP_DESIG_NAME': 'Designation name already exists. Please choose another one.',
    'DUP_TEAM_NAME': 'Team name already exists. Please choose another one',
    'DUP_SITE_NAME': 'Site name already exists. Please choose another one',

    'ACCOUNT_NAME': 'Choose the Account Name',
    'COUNTRY_NAME': 'Choose the Country!',
    'COUNTRY_ID': 'Choose the Role of the Employee',

    'FEATURE_NAME': 'Please Enter the Feature Name',
    'FEATURE_DESCRIPTION': 'Please Enter the Feature Description',

    'LOCATION_NAME': 'Please Enter the Location Name',
    'LOCATION_ICON': 'Please select the icon',

    'PROJECT_NAME': 'Please Enter the Project Name',
    'PROJECT_ACCOUNT_NAME': 'Choose the Account Name',
    'PROJECT_UNIT_NAME': 'Choose the Unit Name',
    'DUPLICATE_UNIT': 'Unit name already exists. Enter another one.',
    'PROJECT_TEMPLATE_NAME': 'Choose the Template Name',
    'PARENT_PROJECT': 'Choose the Parent Project',
    'PROJ_END_DATE': 'Choose the project end date',
    'PROJECT_CHANNEL': 'Please Enter the Project Channel',
    'PROJECT_CODE': 'Please Enter the Project Code',
    'PROJECT_EVENT_TITLE': 'Please Enter Project Event Name',
    'PROJECT_EVENT_DATE': 'Please Enter Date',
    'PROJECT_MEMBER': 'Select a member',
    //Project Lessons and Risks
    'LESSON_VAL_TITLE': 'Title is required',
    'VAL_MITIGATION': 'Mitigation is required',
    'STATUS': 'Status is required',
    'SEVERITY_VAL': 'Severity is required',
    'PROBABILITY_VAL': 'Probability is required',

    'MEMBER_ROLE': 'Select a role',
    'TEAM_NAME': 'Please Enter the Team Name',
    'TASK_NAME': 'Please Enter the Task Name',
    'DESIGNATION_NAME': 'Please Enter the Designation Name',
    'QUERY_NAME' : 'Please Enter Name',
    'UNIT_NAME': 'Please Enter the Unit Name',
    'TEMPLATE_NAME': 'Please Enter the template Name',
    'LOADING': 'Loading...',
    'NO_MATCH_FOUND': 'No Match Found...',
    'NO_DATA_FOUND': 'No Data Found',
    'NO_WEEKWISE_REPORT': 'No weekwise report available',
    'NO_PROJECT_REPORT': 'No project report available',
    'NO_FEATURE_REPORT': 'No feature report available',
    'NO_TASK_FOUND': 'No tasks found',
    'PHONE_NUMBER_LENGTH': 'Mobile Number should be 10 digit',
    'ORG_NAME': 'Please enter organization name',
    'ORG_URL': 'Please enter organization url',
    'USERS_ALLOWED': 'Please enter number of users',
    'DAILY_WORK_HOURS': 'Please enter Daily Work Hours',
    'WEEK_WORK_DAYS': 'Please enter Weekly Work Days',
    'ORG_DOMAIN': 'Please enter the domain',
    'ADMIN_USERNAME': 'Please enter the admin email',
    'PMO_EMAIL': 'please enter the pmo email',
    'HOLIDAY_DATE': 'Please enter the holiday date',
    'PROJ_NAME_EXISTS': 'Project name already exists',
    'SKILL_NAME_EXISTS': 'Skill name already exists',
    'ACC_NAME_EXISTS': 'Account name already exists',
    'GROUP_NAME': 'Please Enter the Group Name',
    'GROUP_DESCRIPTION': 'Please Enter the Group Description',
    'GROUP_EMAIL': 'Please Enter the Group Email',
    'GROUP_NAME_EXISTS': 'Group name already exists',
    'QUESTION_TYPE': 'Question type is required',
    'DESC': 'Description is required',
    'VENDOR_TYPE_NAME' : 'Please enter the vendor type',
    
    //Expense report validators
    'CLAIM_NAME_REQUIRED': 'Please enter the claim',
    'CATEGORY_REQUIRED': 'Please select a category',
    'INVOICE_DATE_REQUIRED': 'Please choose a invoice date',
    'AMOUNT_REQUIRED': 'Please enter the amount',
    'BILL_REQUIRED': 'Please enter the bill number',
    'APPROVER_REQUIRED': 'Approver is required',
    'BILLNO_REQUIRED': 'Bill number is required',

    //categories
    'CATEGORY_NAME_REQUIRED': 'Category name is required',

    //links
    'LINKS_URL_REQD': 'URL is required',
    'LINK_TAGS_REQD': 'Tags are required',
    'LINK_TYPE_REQD': 'Link type is required',
    'LINK_TITLE_REQD': 'Link title is required',
    'TAG_NAME_EXISTS': 'Tag name already exists',
    'CAT_NAME_REQD': 'Category required',
    //project collation and sharing
    'GROUP_REQUIRED': 'Project group name is required',
    'SHARE_CODE_REQUIRED': 'Shareable code is required to add a new project',
    //Conference rooms
    'ROOM_NAME': 'Conference room name is required',
    'SEATS': 'Number of seats required',
    //training
    'TRAINING_TITLE': 'Please Enter the Training Title',
    'TRAINING_DESCRIPTION': 'Please Enter the Training Description',
    'TRAINING_TYPE': 'Please Select a Training Type',
    'TRAINING_DATE': 'Please Select Date ',
    'TRAINING_TIME': 'Please Select Time ',
    'TRAINING_DURATION': 'Please Enter Training Duration',
    'TRAINING_TRAINER': 'Please Select Trainer Name',
    'TRAINING_LOCATION': 'Please Select Training Location',
    'ONLINE_PLACEHOLDER': 'Online/Classroom',
    'TRAINING_TYPE_PLACEHOLDER': 'Training Type',
    'SKILL_NAME': 'Please Enter the Skill Name',
    'TRAINING_TITLE_PLACEHOLDER': 'Training Title',
    'DATE_PLACEHOLDER': 'Date',
    'TIME_PLACEHOLDER': ' Time',
    'LOCATION_PLACEHOLDER': 'Location/Meeting Link',
    'TRAINER_PLACEHOLDER': 'Select a Trainer',
    'TRAINER_FIRSTNAME': 'First name',
    'TRAINER_LASTNAME': 'Last name',
    'TRAINER_FIRST_NAME': 'First name is required',
    'TRAINER_LAST_NAME': 'Last name is required',
    'TRAINING': 'Select a Training',
    'DESCRIPTION_PLACEHOLDER': 'Description',
    'LABEL_PLACEHOLDER': 'Label',
    'URL_PLACEHOLDER': 'URL',
    'CONTENT_PLACEHOLDER': 'Type in your Content',
    'HOURS_PLACEHOLDER': 'Hours',
    'MINS_PLACEHOLDER': 'Mins',
    'RATING': 'Rating is reaquired',
    'USER_SKILL_NAME': 'Skill name is required',
    'SKILL_COMPETENCE': 'Please Enter Training Level',
    //training Requests
    'REQUEST_TITLE': 'Title required',
    'REQUEST_REASON': 'Reason required',

    //policies
    'CATEGORY_NAME': 'Category is required',
    'CATEGORY_NAME_EXISTS': 'Category name already exists',
    //audit template
    'QUESTION': 'Please Enter the Question',

    //notes
    'NOTES_TITLE': 'Notes title required',
    'NOTES_CONTENT': 'Notes description required',
    'SITE_NAME': 'Site required',

    //asset management
    'ASSET_TITLE': 'Asset title required',
    'COMPANY_NAME': 'Company name required',
    'MODEL_NAME': 'Model name required',
    'SERIAL_NUMBER': 'Serial number required',

    'UNAUTHORIZED_ACCESS': 'Unauthorized access',

    //contracts
    'ACTIVE_CONTRACT_END_DATE': 'Contract cannot be active after the end date',

    //Admin Dashboard Banner
    'BANNER_DATE_ERROR': "End date must be on or after start date",
    'BANNER_TOOL_TIP': 'Label is required',
    'BANNER_IMAGE': 'Banner Image is required',
    'BANNER_IMAGE_TYPE_MISMATCH': 'Upload only PNG or JPG files',
    'BANNER_IMAGE_SIZE_EXCEEDED': 'Image size should be less than 4 MB',
    'FILE_LIMIT_EXCEEDED': 'Only one image can be uploaded',
    'FILE_DIMENSION_ERROR': "Image dimensions should be 1920 x 200px",
}