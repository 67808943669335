import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  UtilsService, CommunicationService, CompRouterService
} from '../../../../helpers/services/index';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { ImageUpload } from '../../../image-upload.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  @Input() resource: any;
  @Input() fieldList: any;
  @Input() data: any;
  @Input() disableBtn: any;
  @Input() isAdditionalFieldValid: any;
  @Input() additionalErrorMessage: any;
  @Output() submitForm = new EventEmitter<Object>();
  @Output() selectedRadioValue = new EventEmitter<Object>();
  @Output() selectedDropdownValue = new EventEmitter<Object>(); 
  @Output() delete = new EventEmitter<Object>();
  @Output() addImage = new EventEmitter<Object>();
  @Output() addItem = new EventEmitter<Object>();
  constructor(public utils: UtilsService, private imageUpload: ImageUpload) { }

  ngOnInit() {
  }

  submit(form: NgForm) {
    let obj = { data: this.data, form: form }
    this.submitForm.emit(obj);
    // form.resetForm();
  }

  deleteForm() {
    this.delete.emit(this.data);
  }

  clearData(field) {
    this.data[field.model] = "";
    this.data[field.dataObject] = "";
    // this.isAdditionalFieldValid = false;
    // this.additionalErrorMessage = [];
    setTimeout(() => { this.utils.materialInput() }, 200);
  }

  myCallback(field, event) {
    this.selectedDropdownValue.emit({ field: field, value: event });
    if (event[field.model]) {
      this.data[field.model] = event[field.model];
      this.data[field.dataObject] = event[field.dataField]
    } else if (event[field.dataField]) {
      this.data[field.dataObject] = event[field.dataField]
    }
  }

  searchExisting(field) {
    if (field.addNew) {
      let exist = false;
      field.new = false;
      if (this.data[field.model]) {
        if (field.source) {
          field.source.forEach(item => {
            if (item[field.model].toLowerCase().includes(this.data[field.model].toLowerCase())) {
              exist = true;
            }
          });
        }
        if (!exist) {
          field.new = true;
        }
      }
    }
  }

  add(field) {
    let obj = {
      item: field.model,
      data: this.data[field.model]
    }
    // this.isAdditionalFieldValid = false;
    // this.additionalErrorMessage = [];
    this.addItem.emit(obj);
  }

  isItemSelected(item, field) {
    if (item && item[field]) {
      return true;
    }
    return false;
  }

  isFormValid() {
    let valid = true;
    this.fieldList.forEach(field => {
      if (field.required && field.type == 'selection') {
        valid = valid && this.data[field.model] && this.data[field.dataObject]
      }
    });
    return valid;
  }

  selectedRadio(item) {
    this.selectedRadioValue.emit(item);
  }

  dateToChange(field, event) {
    const date1 = moment(event).format('YYYY-MM-DD');
    this.data[field.model] = date1;
  }

  // Image upload
  fileEvent(event: any, field) {
    if (event.target.files && event.target.files[0]) {
      this.imageUpload.readFiles(event.target.files, 0, (fileName, resized_jpeg) => {
        field.imageUrl = resized_jpeg;
        this.addImage.emit(resized_jpeg);
        this.data[field.model] = fileName;
        this.data[field.updated] = true;
      });
    }
  }

  clearImage(field) {
    if (this.data[field.model] === "") {
      this.data[field.updated] = false;
      field.imageUrl = ""
    }
  }

  selectStar(value, field) {
    this.data[field.model] = value;
  }
}
