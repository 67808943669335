import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { API } from '../../../constants/index';
import { DataService } from '../core/interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class VendorsService {
  constructor(private dataService: DataService) { }

  getAllVendorsByOrg(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.VENDORS + API.GET_ALL_VENDORS_BY_ORG, req);
  }

  addUpdateVendor(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.VENDORS + API.ADD_UPDATE_VENDOR, req);
  }

  deleteVendor(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.VENDORS + API.DELETE_VENDOR, req);
  }


  getVendorDetailsById(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.VENDORS + API.GET_VENDOR_DETAILS_BY_ID, req);
  }

  getSubContractorsByVendor(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.VENDORS + API.GET_SUB_CONTRACTORS_BY_VENDOR, req);
  }

  addUpdateSubContractor(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.VENDORS + API.ADD_UPDATE_SUBCONTRACTOR, req);
  }

  deleteSubContractor(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.VENDORS + API.DELETE_SUBCONTRACTOR, req);
  }

  getAllVendorNotes(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.VENDORS + API.GET_ALL_VENDOR_NOTES, req);
  }

  addUpdateVendorNote(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.VENDORS + API.ADD_UPDATE_VENDOR_NOTE, req);
  }

  deleteVendorNote(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.VENDORS + API.DELETE_VENDOR_NOTE, req);
  }

  getVendorContracts(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.VENDORS + API.GET_VENDOR_CONTRACTS, req);
  }

  addUpdateVendorContract(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.VENDORS + API.ADD_UPDATE_VENDOR_CONTRACT, req);
  }

  deleteVendorContract(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.VENDORS + API.DELETE_VENDOR_CONTRACT, req);
  }

  getAllFilesByVendorId(req){
    return this.dataService.post(KRONOS.BASE + KRONOS.VENDORS + API.GET_ALL_FILES_BY_VENDOR_ID, req);
  }

  addVendorFile(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.VENDORS + API.ADD_VENDOR_FILE, req);
  }

  updateVendorFiles(req){
    return this.dataService.post(KRONOS.BASE + KRONOS.VENDORS + API.UPDATE_VENDOR_FILES, req);
  }

  getAllVendorTypesByOrg(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + API.GET_ALL_VENDOR_TYPES_BY_ORG, req);
  }

  getAllContactsByVendor(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.VENDORS + API.GET_ALL_CONTACTS_BY_VENDOR, req);
  }

  addUpdateVendorContact(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.VENDORS + API.ADD_UPDATE_VENDOR_CONTACT, req);
  }

  deleteVendorContact(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.VENDORS + API.DELETE_VENDOR_CONTACT, req);
  }
}
